// import OMath from '@/utils/math'
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('common', [
      'globalOptions']),
    makeStatusOptions() {
      return [
        {
          id: 1,
          name: 'Enable'
        },
        {
          id: 0,
          name: 'Disable'
        },

      ]
    },
    insuredEmployeeTypeOption(){
      return[
        {
          name:'Indoor',
          id: 'Indoor'
        },
        {
          name:'Outdoor',
          id: 'Outdoor'
        },
      ]
    },
    allPolicyType(){
      return[
        {
          name:'All',
          id: '0'
        },
        {
          name:'Pending Supporting Doc.',
          id: '1'
        },
        {
          name:'Pending Issue Policy',
          id: '2'
        },
        {
          name:'Pending Policy Doc.',
          id: '3'
        },
        {
          name:'Pending Post to Hire Purchase',
          id: '4'
        },
        {
          name:'Pending to send policy',
          id: '7'
        },
        {
          name:'Paid On Account ',
          id: '5'
        },
        {
          name:'Client - Pending Refund',
          id: '6'
        }
      ]
    },
    pendingList(){
      return[
        {
          name:'Pending Renew Quote',
          id: 'pending_quota'
        },
        {
          name:'Paid & Issued',
          id: 'paid'
        },
        {
          name:'Expired',
          id: 'pending_expired'
        }
      ]
    },
    upComingList(){
      return[
        {
          name:'Upcoming Renew',
          id: 'upcoming_renew'
        },
        {
          name:'Expired',
          id: 'upcoming_expired'
        }
      ]
    },
    dispatchOption(){
      return[
        {
          name:'Collect',
          id: 1
        },
        {
          name:'Mail',
          id: 2
        },
        {
          name:' Keep in office',
          id: 3
        },
        {
          name:' Other',
          id: 4
        }
      ]
    },
    wayOptions(){
      return[
        {
          name:'On Pro Rate',
          id: 1
        },
        {
          name:'Follow Refund Table',
          id: 2
        },
        {
          name:'Manual',
          id: 3
        }
      ]
    },
    wayExceeding(){
      return[
        {
          rate:'20% od annual rate',
          refund:'80% od annual rate',
          month: '1 month',
          id: 80
        },
        {
          rate:'30% od annual rate',
          refund:'70% od annual rate',
          month: '2 months',
          id: 70
        },
        {
          rate:'40% od annual rate',
          refund:'60% od annual rate',
          month: '3 months',
          id: 60
        },
        {
          rate:'50% od annual rate',
          refund:'50% od annual rate',
          month: '4 months',
          id: 50
        },
        {
          rate:'60% od annual rate',
          refund:'40% od annual rate',
          month: '5 months',
          id: 40
        },
        {
          rate:'70% od annual rate',
          refund:'30% od annual rate',
          month: '6 months',
          id: 30
        },
        {
          rate:'80% od annual rate',
          refund:'20% od annual rate',
          month: '7 months',
          id: 20
        },
        {
          rate:'Full annual rate',
          refund:'0% od annual rate',
          month: '8 months',
          id: 0
        },
      ]
    },
    selectDateOptions(){
      return[
        {
          name: 'Policy Effective Date',
          id: 1
        },
      ]
    },
    searchTypeOptions(){
      return[
        {
          name: 'Phone',
          id: 1
        },
        {
          name: 'Name',
          id: 2
        },
        {
          name: 'ID #',
          id: 6
        },

      ]
    },
    searchSecondTypeOptions(){
      return[
        {
          name: 'VRN #',
          id: 5
        },
        {
          name: 'Policy #',
          id: 3
        },
        {
          name: 'CN #',
          id: 7
        }
      ]
    },
    buttonClassOption(){
      return[
        {
          name: 'button-inforce',
          id: 200
        },
        {
          name: 'button-renew',
          id: 201
        },
        {
          name: 'button-endorsement',
          id: 203
        },{
          name: 'button-cancel',
          id: 204
        }
      ]
    },
    buttonColorClassOption(){
      return[
        {
          name: 'color-info',
          id: 30
        },
        {
          name: 'color-cancel',
          id: 40
        },
        {
          name: 'color-cancel',
          id: 50
        }
      ]
    },
    endorsementTypeOptions(){
      return[
        {
          name: 'Change Car',
          id: 1
        },
        {
          name: 'Change Driver',
          id: 2
        },
        {
          name: 'Change Plan Type',
          id: 3
        },
        {
          name: 'VRN',
          id: 4
        },
        {
          name: 'Change Contact Detail',
          id: 5
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementContractorTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Policy period',
          id: 3
        },
        {
          name: 'Change Work Site Location',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementDomesticTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Domestic Helper Info',
          id: 3
        },
        {
          name: 'Change Insured Location',
          id: 4
        },
        {
          name: 'Change Policy period',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementEmpolyeesTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Employees Info',
          id: 3
        },
        {
          name: 'Change Insured Location',
          id: 4
        },
        {
          name: 'Change Policy period',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementBusinessTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Location of Risk',
          id: 3
        },
        {
          name: 'Change Policy period',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    statusOptions(){
      return[
        {
          name: 'Pending Payment',
          id: 10
        },
        {
          name: 'Inforce',
          id: 20
        },
        {
          name: 'Inforce',
          id: 30
        },
        {
          name: 'Cancelled',
          id: 40
        },
        {
          name: 'Expired',
          id: 50
        },
      ]
    },
    policyTypeOption(){
      return[
        {
          name: 'New Policy',
          id: 200
        },
        {
          name: 'Renew Policy',
          id: 201
        },
        {
          name: 'Endorsement',
          id: 203
        },{
          name: 'Cancel Policy',
          id: 204
        }
      ]
    },
    userTypeOptions() {
      return [
        {
          name: 'Personal',
          id: 1
        },
        {
          name: 'Company',
          id: 2
        }
      ]
    },
    planTypeOptions() {
      return [
        {
          name: 'Single',
          id: 5
        },
        {
          name: 'Annual',
          id: 6
        }
      ]
    },
    periodInsuranceOptions() {
      return [
        {
          name: 'One Year',
          id: 1
        },
        {
          name: 'Two Year',
          id: 2
        }
      ]
    },
    planOptions() {
      return [
        {
          name: 'T Plate',
          id: 3
        },
        {
          name: 'Named Driver',
          id: 4
        }
      ]
    },
    statusOption() {
      return[{
        name: 'Active',
        id: '1'
      },
      {
        name: 'Pending',
        id: '2'
      },
      {
        name: 'Banned',
        id: '0'
      }]
    },
    mathedOptions(){
      let {policy_dispatch_mathed=[]} = this.globalOptions
      return policy_dispatch_mathed||[]
      return [
        {
          name: 'Collect ',//发动机
          id: 1
        },
        {
          name: 'Mail ',//家务助理
          id: 2
        },
        {
          name: 'Keep in office',//打工度假
          id: 3
        },
        {
          name: 'Other',//旅行
          id: 4
        },
        {
          name: 'E policy',//旅行
          id: 5
        },
      ]
    },
    documentOptions(){
      return [
        {
          name: 'Driving Licence',//发动机
          id: 4
        },
        {
          name: 'HKID',//家务助理
          id: 2
        },
        {
          name: 'VRD',//打工度假
          id: 6
        },
        {
          name: 'NCD',//旅行
          id: 5
        },
        {
          name: 'Signed Proposal Form ',//室内装饰
          id: 7
        },
        {
          name: 'other',//留学生
          id: 20
        },
      ]
    },
    contractorDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'Contract',
          id: 9
        },
        {
          name: 'Signed Proposal Form ',
          id: 7
        },
        {
          name: 'other',
          id: 20
        },
      ]
    },
    travelDocumentOptions(){
      return [
        {
          name: 'HKID',
          id: 2
        },
        {
          name: 'Singed Proposal Form',
          id: 7
        },
        {
          name: 'other',
          id: 20
        },
      ]
    },
    employeeDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'MPF',
          id: 9
        },
        {
          name: 'Declaration Form',
          id: 11
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    businessDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'MPF',
          id: 9
        },
        {
          name: 'Declaration Form',
          id: 11
        },
        {
          name: 'Singed Proposal Form',
          id: 7
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    conEmployeeDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    productNameOptions(){
      return [
        {
          name: 'Construction Insurance',//发动机
          id: 11
        },
        {
          name: 'Travel Insurance',//家务助理
          id: 12
        },
        {
          name: 'Home Insurance',//打工度假
          id: 13
        },
        {
          name: 'Employee Compensation',//旅行
          id: 14
        },
        {
          name: 'Business Package Insurance ',//室内装饰
          id: 15
        },
        {
          name: 'Domestic Helper Insurance',//留学生
          id: 16
        },
        {
          name: 'Construction Employee Compensation',//留学生
          id: 17
        },
      ]
    },
    // 產品類型
    productTypeOptions() {
      return [
        {
          name: 'Motor',//发动机
          id: 10
        },
        {
          name:'Contractors\' All Risks',
          id: 11
        },
        {
          name: 'Travel',
          id: 12
        },
        {
          name:'Home',
          id: 13
        },
        {
          name: 'Employees\' Compensation',
          id: 14
        },
        {
          name: 'Business Package',
          id: 15
        },
        {
          name: 'Domestic Helper',
          id: 16
        },
        {
          name: 'Construction Employee Compensation',
          id: 17
        },
        {
          name: 'Others',
          id: 18
        },
      ]
    },

    //验证身份 香港居民身份证/护照
    personalIdTypOptions() {
      return [
        {
          name: 'HKID',
          id: 1
        },
        {
          name: 'Passport',
          id: 2
        },
      ]
    },
    idTypeOptions() {
      return [
        {
          name: 'HKID',
          id: 1
        },
        {
          name: 'Passport',
          id: 2
        },
        {
          name: 'BR',
          id: 3
        }
      ]
    },
    carClassOptions(){
      return[
        {
          name: 'Private Car',
          id: 1001
        },
        {
          name: 'Commercial Vehicle',
          id: 1002
        },
        {
          name: 'Motorcycle',
          id: 1003
        },
        {
          name: 'Motor trade',
          id: 1004
        }
      ]
    },
    numberFourOptions() {
      return [
        {
          id: 1,
          name: 1
        },
        {
          id: 2,
          name: 2
        },
        {
          id: 3,
          name: 3
        },
        {
          id: 4,
          name: 4
        }
      ]
    },
    yesNoOptions() {
      return [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 0,
          name: 'No'
        }
      ]
    },
    // 一样的，標記非必選吧
    yesNo2Options() {
      return [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 0,
          name: 'No'
        }
      ]
    },
    allCoverTypeOptions() {
      return [
        {
          id: 1,
          name: 'Third Party'//第三方
        },
        {
          id: 2,
          name: 'Comprehensive'//全面
        },
        {
          id: 0,
          name: 'All'
        },
      ]
    },
    coverTypeOptions() {
      return [
        {
          id: 1,
          name: 'Third Party'
        },
        {
          id: 2,
          name: 'Comprehensive'
        },
      ]
    },
    classOptions() {
      return [
        {
          id: 11,
          name: 'Private Car'
        },
        {
          id: 12,
          name: 'Commercial Vehicle'
        },
        {
          id: 14,
          name: 'Motorcycle'
        },
        {
          id: 21,
          name: 'Domestic Helper'
        },
        {
          id: 22,
          name: 'Working Holiday'
        },
        {
          id: 23,
          name: 'Travel'
        },
        {
          id: 24,
          name: 'Interior Decoration'//室内装饰
        },
        {
          id: 25,
          name: 'Overseas Student'
        },
        {
          id: 26,
          name: 'Home & Fire'
        }
      ]
    },

    insureOptions() {
      return [
        // {
        //   id: 1,
        //   name: 'AXA',
        //   logo: require('@/assets/images/company/item-1@2x.png')
        // },
        {
          id: 2,
          name: 'QBE',
          logo: require('@/assets/images/company/logo_1.png')
        },
        {
          id: 5,
          name: 'ASIA INSURANCE',
          logo: require('@/assets/images/company/logo_2.png')
        },
        {
          id: 3,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/company/logo_3.png')
        },
        {
          id: 4,
          name: 'TARGET INSURANCE',
          logo: require('@/assets/images/company/logo_4.png')
        }
      ]
    },
    newInsureOptions() {
      return [
        {
          id: 17,
          name: 'Allianz',
          logo: require('@/assets/images/company/Allianz.png')
        },
        {
          id: 3,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/company/logo_3.png')
        },
        {
          id: 15,
          name: 'Dah Sing',
          logo: require('@/assets/images/company/DahSing.png')
        },
        {
          id: 12,
          name: 'Zurich',
          logo: require('@/assets/images/company/Zurich.png')
        }
      ]
    },
    insureSVGOptions() {
      return [

        {
          id: 2,
          name: 'QBE',
          logo: require('@/assets/images/insurer/qbe.svg')
        },
        {
          id: 5,
          name: 'ASIA INSURANCE',
          logo: require('@/assets/images/insurer/asia.svg')
        },
        {
          id: 3,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/insurer/boc.svg')
        },
        {
          id: 4,
          name: 'TARGET INSURANCE',
          logo: require('@/assets/images/insurer/target.svg')
        }
      ]
    },
    driverExperienceOptions() {
      return [
        {
          id: 1,
          name: 'less than 1 years',
        },
        {
          id: 2,
          name: '1-2  years',
        },
        {
          id: 3,
          name: '2-5 years',
        },
        {
          id: 4,
          name: 'more than 5 years',
        }
      ]
    },
    relationshipOptions() {
      return [
        {
          id: 8,
          name: 'Self',
        },
        {
          id: 1,
          name: 'Parent',
        },
        {
          id: 2,
          name: 'Child',
        },
        {
          id: 3,
          name: 'Spouse',
        },
        {
          id: 4,
          name: 'Relative',
        },
        {
          id: 5,
          name: 'Friend',
        },
        {
          id: 6,
          name: 'Employee',
        },
        {
          id: 7,
          name: 'Employer',
        },

      ]
    },
    pathProductId(){
      return[
        {
          name:'/motor',
          id:10
        },
        {
          name:'/contractors',
          id:11
        },
        {
          name:'/travel',
          id:12
        },      {
          name:'/home',
          id:13
        },
        {
          name:'/employee',
          id:14
        },
        {
          name:'/business',
          id:15
        },
        {
          name:'/domestic',
          id:16
        },
        {
          name:'/construction',
          id:17
        },
        {
          name:'/other',
          id:18
        },
      ]
    }
  },
  created() {
    // 檢查是否有遺漏的 options 數據未獲取成功，重新請求一次
    this.checkOptionsData()
  },
  methods: {
    ...mapActions('common', [
      'updateGlobalOptions',
      'checkOptionsData']),
    getOptionName(target, id, originData) {
      try {
        let data
        if(originData) {
          data= originData
        }
        else {
          const targetArr = target.split('.')
          data = this[targetArr[0]]
          if(targetArr.length > 1) {
            data = this[targetArr[0]][targetArr[1]]
          }
        }

        let result = ''
        data.some(item => {
          if (item.id == id) {
            result = item.name
            return true
          }
          return false
        })
        return result
      } catch (e) {
      }
    },
    getOptionData(target, id) {
      let result = {}
      this[target].some(item => {
        if (item.id == id) {
          result = item
          return true
        }
        return false
      })
      return result
    },
    getAreaName(target, id) {
      let result = ''
      this.globalOptions.area[target].some(item => {
        if (item.id == id) {
          result = item.name
          return true
        }
        return false
      })
      return result
    },
    getAreaData(target, id) {
      let result = {}
      this.globalOptions.area[target].some(item => {
        if (item.id == id) {
          result = item
          return true
        }
        return false
      })
      return result
    },
    changeTime(time){
      let arr =[]
      let result = ''
      if(time){
        arr = time.split('-')
        result = arr[2]+'-'+arr[1]+'-'+arr[0]
      }
      return result
    },
    showHkDate(time){
      if(time)
      {
        return  dayjs(time).format('DD-MM-YYYY')
      }else return  '-'
    },
    showHKTime(time){
      if(time)
      {
        return  dayjs(time).format('DD-MM-YYYY HH:mm:ss')
      }else return  '-'
    },
    showHKTimeSecond(time){
      if(time)
      {
        return  dayjs(time).format('DD/MM/YYYY HH:mm')
      }else return  '-'
    },
    showHKTimeArr(time){
      if(time)
      {
        return  dayjs(time).format('DD/MM/YYYY')
      }else return  '-'
    },
    showMonth(time){
      if(time)
      {
        return  dayjs(time).format('DD MMM YYYY')
      }else return  '-'
    },
    showYearMonth(time){
      if(time)
      {
        return  dayjs(time).format('YYYY MMM')
      }else return  '-'
    },
    getNcdList(class_id){
      let list = []
      this.globalOptions.ncd.some(item=>{
        if(item.class_id===class_id)
          list = item.item
      })
      return list
    },
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    toFixed(data) {
      const arr = String(data).split('.')
      let num = arr[0] // 整数部分
      let resultArr = []
      while (num.length > 0) {
        resultArr.unshift(num.slice(-3))
        num = num.slice(0, -3)
      }
      let result = resultArr.join(',')
      return result
    }
  },


}
